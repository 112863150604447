<template>
<div class="admin-index">
  <el-page-header @back="goBack" :content="title"></el-page-header>
  <div class="content" v-loading="loading">
    <h2 class="content-title">基本信息</h2>
    <el-form label-position="right" label-width="100px" :rules="rules" ref="ruleForm" class="form" :model="ruleForm">
      <el-form-item label="用户名：" prop="username">
        <el-input v-model.number="ruleForm.username" placeholder="输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="姓名：" prop="name">
        <el-input v-model="ruleForm.name" placeholder="​输入姓名"></el-input>
      </el-form-item>
      <el-form-item label="角色：" prop="role">
        <el-select class="select" @change="roleChange" v-model="ruleForm.role" filterable placeholder="请选择角色">
          <el-option v-for="item in role" :key="item.role_id" :label="item.name" :value="item.role_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单位：" prop="work_id" v-if="ruleForm.role != 1">
        <el-select class="select" filterable v-model="ruleForm.work_id" placeholder="请选择角色">
          <el-option v-for="item in workList" :key="item.work_id" :label="item.name" :value="item.work_id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="机构城市：" v-if="ruleForm.role == 3">
        <el-select class="select-city" @change="getCity" v-model="ruleForm.value1" placeholder="所属省">
          <el-option v-for="item in options1" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
        <el-select class="select-city" v-model="ruleForm.value2" placeholder="所属市">
          <el-option v-for="item in options2" :key="item.code" :label="item.text" :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="密码：" prop="password">
        <el-input v-model="ruleForm.password" type="password" autocomplete="new-password" placeholder="输入密码" show-password></el-input>
      </el-form-item>
      <p class="tip">默认设置初始密码为tongliao2024</p>
      <el-form-item label="确认密码：" prop="re_password">
        <el-input v-model="ruleForm.re_password" type="password" autocomplete="new-password" placeholder="确认密码" show-password></el-input>
      </el-form-item>
      <el-form-item label="状态：" prop="status">
        <el-switch v-model="ruleForm.status" active-text="开启" inactive-text="关闭"></el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm" :loading="submitLoading" :disabled="canSubmit" v-throttle>{{type=='add'?'立即创建':'保存'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</div>
</template>

<script>
export default {
  name: "inspect-index",
  data() {
    var validatePass = (rule, value, callback) => {
      if (this.ruleForm.re_password !== "") {
        this.$refs.ruleForm.validateField("re_password");
      }
      callback();
    };
    var validatePass2 = (rule, value, callback) => {
      console.log(value, this.ruleForm.password);
      if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      id: "",
      title: "",
      loading: false,
      submitLoading: false,
      ruleForm: {
        name: "",
        username: "",
        password: "",
        re_password: "",
        role: "",
        status: true,
        value1: '',
        value2: '',
        work_id: ''
      },
      rules: {
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }],
        username: [{
          required: true,
          message: "请输入管理员名",
          trigger: "blur"
        }],
        mobile: [{
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            type: "number",
            message: "手机号必须为数字值"
          },
        ],
        password: [{
            message: "请输入密码",
            trigger: "blur"
          },
          {
            validator: validatePass,
            trigger: "blur"
          },
        ],
        re_password: [{
            message: "请再次输入密码",
            trigger: "blur"
          },
          {
            validator: validatePass2,
            trigger: "blur"
          },
        ],
        role: [{
          required: true,
          message: "请选择角色",
          trigger: "blur"
        }],
        job: [{
          required: true,
          message: "请选择岗位",
          trigger: "blur"
        }],
      },
      role: [],
      createLoading: false,
      currentPage: 1,
      options1: [],
      options2: [],
      options3: [],
      workList: [],
      allWorkList: []
    };
  },
  mounted() {
    let r = this.$route;
    let t = r.params.type;
    let id = r.query.id;
    this.type = t;
    if (t == "add") {
      this.title = "添加管理员";
    } else if (t == "edit") {
      this.title = "编辑管理员";
      this.id = id;
    }
    this.init();
  },
  computed: {
    canSubmit() {
      return !(this.ruleForm.name && this.ruleForm.username && this.ruleForm.role);
    },
  },
  methods: {
    async init() {
      this.loading = true;
      let city = await this.$api({
        method: "get",
        url: "/api/province",
      });
      if (city && city.code == 0) {
        this.options1 = city.data;
      }
      let res = await this.$api({
        method: "get",
        url: "/api/organ/admindetial",
        data: {
          id: this.id || 0
        }
      });
      this.loading = false;
      if (res.code == 0) {
        console.log(res)
        this.role = res.data.role;
        this.allWorkList = res.data.work_place;
        let data = res.data.list[0];
        console.log(data)
        if (this.type == 'edit') {
          await this.getCity(data.province_code + '');
          this.ruleForm.name = data.name;
          this.ruleForm.username = data.username;
          this.ruleForm.status = data.status == 1 ? true : false;
          this.ruleForm.role = data.role_id;
          this.ruleForm.value1 = data.province_code + '';
          this.ruleForm.value2 = data.city_code + '';
          this.ruleForm.work_id = data.work_id ? data.work_id * 1 : '';
          this.workList = this.allWorkList.filter(e => {
            return e.role_id == data.role_id;
          })
        }
        console.log(this.ruleForm)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async submitForm() {
      this.submitLoading = true;
      let res = await this.$api({
        method: "post",
        url: "/api/organ/adminsave",
        data: {
          id: this.type == 'edit' ? this.id : 0,
          username: this.ruleForm.username,
          password: this.ruleForm.password,
          name: this.ruleForm.name,
          role_id: this.ruleForm.role,
          status: this.ruleForm.status ? 1 : 0,
          province_code: this.ruleForm.value1 || '1',
          city_code: this.ruleForm.value2 || '1',
          work_id: this.ruleForm.work_id || 0
        }
      });
      this.submitLoading = false;
      if (res.code == 0) {
        this.$message({message:this.type == 'add' ? '创建成功' : '保存成功',type:'success'});
        this.$router.go(-1);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    roleChange(e) {
      if (e != 1) {
        this.workList = this.allWorkList.filter(i => {
          return i.role_id == e;
        })
      } else {
        this.ruleForm.work_id = '';
      }

    },
    async getCity(e) {
      this.ruleForm.value2 = '';
      this.options2 = [];
      this.options3 = [];
      let res = await this.$api({
        method: "get",
        url: "/api/city",
        data: {
          q: e
        }
      });
      if (res.code == 0) {
        this.options2 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
  },
  components: {
    // HelloWorld
  },
};
</script>

<style lang="scss" scoped>
.admin-index {
  padding: 10px 4vw;
  min-height: calc(100vh - 150px);

  .title {
    font-size: 20px;
    font-weight: bold;
    text-align: left;
  }

  .content {
    overflow: hidden;
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 0 2vw;
    min-height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;

    .content-title {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
    }

    .form {
      width: 60%;
    }

    .select {
      width: 100%;
    }

    .select-city {
      width: calc((100% - 25px) / 2);
      margin-right: 25px;
    }

    .select-city:nth-of-type(2) {
      margin-right: 0;
    }
  }

  .tip {
    font-size: 14px;
    color: #666;
    padding-left: 100px;
    margin-bottom: 20px;
    margin-top: -20px;
  }
}
</style>

<style lang="scss">
.admin-index {
  .el-form-item__content {
    text-align: left;
  }

  .el-form-item {
    margin-bottom: 32px;
  }

  .el-descriptions {
    font-size: 15px;
  }

  .el-descriptions-item__label:not(.is-bordered-label) {
    width: 80px;
    justify-content: flex-end;
    color: #000;
  }

  .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
    padding-bottom: 22px;
  }
}
</style>
